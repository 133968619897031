import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faAddressCard } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {
    return (
        <footer>
            <div className="" style={{ backgroundColor: 'rgb(24, 24, 24)' }}>
                <div className="container-fluid">
                    <div className="row text-white text-center py-5" id="footer">
                        <div className="col-sm-4 px-5 mb-5" style={{ textAlign: 'justify' }}>
                            <h4 style={{ fontWeight: 'lighter' }}>Unser aktuelle Adresse:</h4>
                            <p>Limbach für Alle <br /> Querstraße 6 <br /> 09212, Limbach-Oberfrohna</p>
                        </div>

                        <div className="col-sm-4 px-5 mb-5" style={{ textAlign: 'justify' }}>
                            <h4 style={{ fontWeight: 'lighter' }}>Unsere Kontaktmöglichkeiten: </h4>
                            <p><FontAwesomeIcon icon={faAddressCard} /> <Link to={"/kontakt"}>Kontaktformular</Link>
                                <br />
                                <a href="mailto:info@limbach-fuer-alle.de"><FontAwesomeIcon icon={faEnvelope} /> info@limbach-fuer-alle.de</a>
                                <br />
                                <a href="https://www.instagram.com/limbachfueralle" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram} /> @limbachfueralle</a>
                            </p>
                        </div>

                        <div className="col-sm-4 px-5 mb-5" style={{ textAlign: 'justify' }}>
                            <h4 style={{ fontWeight: 'lighter' }}>Sonstige Informationen: </h4>
                            {/* Ersetze <a>-Tags durch Link-Komponenten für interne Navigation */}
                            <p><Link to="/impressum" className="link-white">Impressum</Link> <br /> <Link to="/datenschutz" className="link-white">Datenschutz</Link> <br /> <a href='https://www.webdesign-hauschild.de' target='blank'> &copy; T. Hauschild {new Date().getFullYear()}</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
};

export default Footer;